import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { SearchBar } from '@app/components/widgets/searchBar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import {
  planAndMeetStatusStateActions,
  fetchPlanAndMeetListCreator,
  planAndMeetSelector,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { useStyles } from './styles';
import {
  getRoleId,
  getMonthsArray,
  downloadExcel,
} from '@app/helper/plan-and-meet';
import { Label } from '@app/components/elements/Label';
import { Button } from '@mui/material';
import excelLogo from '@app/assets/images/excelLogo.png';
import {
  filterBasedDownloadExcelCreator,
  fetchFilterOptionsCreator,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/slice';
import { filterSelector } from '@app/components/right-sidebar/redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: any, rolesData: any, theme: any) {
  return {
    fontWeight:
      rolesData.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const roles = ['All', 'MR', 'FLM', 'SLM'];
const months = getMonthsArray();

export const PlanAndMeetNavBar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [dropdownValue, setDropdownValue] = useState(['All']);
  const [monthSelected, setMonthSelected] = useState({
    checked: false,
    months: months,
  });
  const [searchError, setSearchError] = useState(false);

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles(monthSelected);
  const searchText = useSelector(
    planAndMeetSelector.getPlanAndMeetSearchText(),
  );
  const gridOptions = useSelector(
    planAndMeetSelector.getPlanAndMeetGridOption(),
  );
  const planAndMeetState = useSelector(
    planAndMeetSelector.getPlanAndMeetState(),
  );
  const filters = useSelector(filterSelector.getFilterState());

  const fetchListData = () => {
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );
    dispatch(fetchPlanAndMeetListCreator());
    dispatch(fetchFilterOptionsCreator());
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(planAndMeetStatusStateActions.setPlanAndMeetSearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleDropdownChange = (event: any) => {
    const {
      target: { value },
    } = event;
    let values = [];
    if (value.indexOf('All') !== -1) {
      values = value.filter((item: any) => item !== 'All');
    } else if (value.length > 0 && value.length < 3) {
      values = value;
    } else {
      values.push('All');
    }
    setDropdownValue(values);
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetSelectedRoles(
        values.map((val: any) => getRoleId(val)),
      ),
    );
    fetchListData();
  };

  const handleMonth = (event: any) => {
    setMonthSelected({
      ...monthSelected,
      [event.target.name]: event.target.checked,
    });
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetMonthSelected(
        event.target.checked,
      ),
    );
    fetchListData();
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchText?.length >= 3 || searchText?.length === 0) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };
  const onPressdownloadExcel = () => {
    dispatch(
      filterBasedDownloadExcelCreator(
        downloadExcel(planAndMeetState, filters, planAndMeetState?.filters),
      ),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div>
          <Button onClick={onPressdownloadExcel}>
            <img
              src={excelLogo}
              alt="Logo"
              className={classes.downloadExcelLogo}
            />
            <Label
              title={'Download Excel'}
              variant="h6"
              textColor={'#322b7c'}
              fontWeight={'800'}
              hideToolTip
            />
          </Button>
        </div>
      </div>
      <div className={classes.searchContainer}>
        <SearchBar
          searchText={searchText}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={searchError}
          placeHolder={"Search by name or employee code"}
          errorMessage={"Please Enter Minimum 3 Characters"}
        />
        <FormControl sx={{ mt: 1.2, ml: 3, mr: 3, width: 100, alignSelf: 'end' }}>
          <span id="demo-multiple-name-label">Roles</span>
          <Select
            labelId="plan-meet-role-dropdown"
            id="plan-meet-role-dropdown"
            multiple
            className={classes.dropdown}
            data-testid={'roleDropDown'}
            defaultValue={dropdownValue}
            value={dropdownValue}
            onChange={handleDropdownChange}
            renderValue={selected => selected.join(', ')}
            onClose={() => !dropdownValue?.length && setDropdownValue(['All'])}
            MenuProps={MenuProps}>
            {roles.map(role => (
              <MenuItem
                key={role}
                value={role}
                data-testid={`dropdown_${role}`}
                disabled={role === 'All'}
                style={getStyles(role, dropdownValue, theme)}>
                <Checkbox checked={dropdownValue.some(val => val === role)} />
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 1.2, width: 120, alignSelf: 'end' }}>
          <span id="demo-multiple-name-label">Month</span>
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={monthSelected.checked}
            onChange={handleMonth}
            name="checked"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            data-testid={'monthSwitchOption'}
          />
        </FormControl>
      </div>
    </div>
  );
};
