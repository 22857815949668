import {makeStyles} from '@mui/styles';
export const useStyles = makeStyles({
  searchField: {
    border: 'transparent',
    borderRadius: 5,
    background: '#f2f2f2',
    padding: '0px',
    opacity: 0.6,
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#000',
    fontSize: 12,
  },
  padding5: {
    padding: '5px',
  },
  error: {
    color: '#E36565',
    position: 'absolute',
    top: -25,
  },
  clear:{
    position: 'absolute',
    right: 10,
    top: 15,
    backgroundColor:'#e9eeef'
  }
});
