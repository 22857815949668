import {createSelector} from '@reduxjs/toolkit';

const getDcrUnlockRequestState = (state: any) => state.dcrUnlockRequestMaster;

const getDcrUnlockRequestSelector = createSelector(
  [getDcrUnlockRequestState],
  data => {
    return data?.dcrUnlockRequestList;
  },
);

const getDcrUnlockRequestLoaderSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.loader,
);

const getDcrUnlockRequestSearchTextSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.searchText,
);

const getDcrUnlockRequestApprovedSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.isApprovedSelected,
);

const getDcrUnlockRequestGridOptionSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.gridOptions,
);

const getDcrUnlockRequestStateSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data,
);

const getDcrUnlockRequestTotalRowSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.totalRows,
);

const getDCRUnlockReasonsSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.dcrUnlockReasons,
);

const getApproveDCRResponseSelector = createSelector(
  [getDcrUnlockRequestState],
  data => data?.approveDCRResponse,
);

const getAppliedFilters = createSelector(
  [getDcrUnlockRequestState],
  data => data?.filters,
);

export const dcrUnlockRequestSelector = {
  getDcrUnlockRequestList: () => getDcrUnlockRequestSelector,
  getDcrUnlockRequestLoader: () => getDcrUnlockRequestLoaderSelector,
  getDcrUnlockRequestSearchText: () => getDcrUnlockRequestSearchTextSelector,
  getDcrUnlockRequestApprovedSelected: () =>
    getDcrUnlockRequestApprovedSelector,
  getDcrUnlockRequestGridOption: () => getDcrUnlockRequestGridOptionSelector,
  getDcrUnlockRequestState: () => getDcrUnlockRequestStateSelector,
  getDcrUnlockRequestTotalRow: () => getDcrUnlockRequestTotalRowSelector,
  getDCRUnlockReasons: () => getDCRUnlockReasonsSelector,
  getApproveDCRResponse: () => getApproveDCRResponseSelector,
  getAppliedFilters: () => getAppliedFilters,
};
