import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';
import FormHelperText from '@mui/material/FormHelperText';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';

type SearchBarProps = {
  handleChange: (params: any) => any;
  handleSearch: (params: any) => any;
  showError: boolean;
  searchText: string;
  placeHolder: string;
  errorMessage: string;
  disabled?: boolean;
  handleClear?: () => any
};

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  handleChange,
  handleSearch,
  showError,
  searchText,
  placeHolder,
  errorMessage,
  disabled = false,
  handleClear = () => { },
}) => {
  const classes = useStyles();
  const handleTextChange = (event: any) => {
    handleChange(event.target.value);
  };

  const handleClearBtn = () => {
    handleClear();
    handleChange('')
  }

  const renderTextField = () => {
    return (
      <TextField
        value={searchText}
        placeholder={placeHolder}
        id="outlined-start-adornment"
        className={classes.searchField}
        onChange={handleTextChange}
        onKeyDown={handleSearch}
        data-testid={'searchBar'}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        autoComplete='off'
      />
    )
  }

  return (
    <FormControl variant="standard" sx={{ mt: 3, flex: 1, width: '100%' }}>
      {showError && (
        <FormHelperText
          id="search-error"
          className={classes.error}
          data-testid={'searchBarError'}>
          {errorMessage}
        </FormHelperText>
      )}
      {disabled ?
        <Tooltip title={searchText}>
          {renderTextField()}
        </Tooltip> :
        renderTextField()
      }
      {searchText?.length > 0 && <ClearIcon onClick={() => handleClearBtn()} className={classes.clear} data-testid={'search-clear-btn'}/>}
    </FormControl>
  );
};
