import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import { fetchRoutesDataWatcher } from '@app/router/redux/routeHandlerSaga';
import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
  addAdminUserWatcher,
  disableAdminUserWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { all, call, spawn } from 'redux-saga/effects';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
  fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
  fetchFilterOptionsWatcher,
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import { fetchPartiesBySpidWatcher, 
  fetchPartyDetailBySpidWatcher, 
  fetchCloningPeriodWatcher, 
  transferPositionUpdateWatcher, 
  fetchPositionCloningListWatcher, 
  clonePositionUpdateWatcher 
} from '@app/screens/module-specific-utilities/pages/position-management/redux'
import {fetchApprovalWorkflowListWatcher, fetchMasterConfigDataWatcher, createApprovalResponseWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';

export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    fetchMTPRecommendationsWatcher,
    fetchFilterOptionsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchPartyDetailBySpidWatcher, 
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    modifyDCRRequestWatcher
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log('Error ', e);
          }
        }
      }),
    ),
  );
}
