import {createAction, createSlice} from '@reduxjs/toolkit';

export const navbarComponentName = {
  home: 'Home',
  accessManagement: 'AccessManagement',
  accessGroupMaster: 'AccessGroupMaster',
  planAndMeet: 'PlanAndMeet',
  dcrUnlockRequest: 'DcrUnlockRequest',
  positionManagement: 'PositionManagement',
  approvalWorkflow: 'ApprovalWorkflow'
};

export const routesInitialState = {
  currentRoute: '',
  isRoutesLoading: false,
  routesRenderingError: false,
  routesenderingErrorMessage: '',
  sideBarOpen: true,
  appRoutes: {},
  navbarComponentName: navbarComponentName.home,
  navbarComponentHeading: '',
};

export const fetchAppRoutesCreator = createAction(
  'ROUTE_HANDLER/FETCH_APP_ROUTES',
);
export const fetchAppRoutesCreatorTypeName = fetchAppRoutesCreator().type;

const routeStateHandler = createSlice({
  name: 'ROUTE_HANDLER',
  initialState: routesInitialState,
  reducers: {
    updateScreen: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        currentRoute: payload,
      };
    },
    setRoutesLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isRoutesLoading: payload,
      };
    },
    setRoutesRenderingError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        routesRenderingError: payload,
      };
    },
    setRoutesRenderingErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        routesenderingErrorMessage: payload,
      };
    },
    clearError: prevState => {
      return {
        ...prevState,
        routesRenderingError: false,
        routesenderingErrorMessage: '',
      };
    },
    setAppRoutes: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        appRoutes: payload,
      };
    },
    setSideBarOpen: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        sideBarOpen: payload,
      };
    },
    setNavbarComponentName: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        navbarComponentName: payload,
      };
    },
    setNavbarComponentHeading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        navbarComponentHeading: payload,
      };
    },
  },
});

export const routeStateActions = routeStateHandler.actions;
export const routeStateReducer = routeStateHandler.reducer;
